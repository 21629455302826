/* RESPONSIBLE TEAM: team-phone */
/* eslint-disable @intercom/intercom/no-bare-strings */

export type FinVoiceVoiceOptions = {
  value: FinVoiceVoice;
  text: string;
};

export type FinVoiceVoice =
  | 'alloy'
  | 'shimmer'
  | 'echo'
  | 'ash'
  | 'ballad'
  | 'coral'
  | 'sage'
  | 'verse'
  | 'Jeff'
  | 'Mark'
  | 'Juniper'
  | 'Cassidy'
  | 'Thomas'
  | 'Lucan'
  | 'Shelly'
  | 'Shelby'
  | 'Hope'
  | 'Chris'
  | 'Clara'
  | 'Nichalia'
  | 'Archer'
  | 'Sean';

export const FIN_VOICE_VOICE_OPTIONS: FinVoiceVoiceOptions[] = [
  { value: 'alloy', text: 'Alloy' },
  { value: 'shimmer', text: 'Shimmer' },
  { value: 'echo', text: 'Echo' },
  { value: 'ash', text: 'Ash' },
  { value: 'ballad', text: 'Ballad' },
  { value: 'coral', text: 'Coral' },
  { value: 'sage', text: 'Sage' },
  { value: 'verse', text: 'Verse' },
];

export const FIN_VOICE_11LABS_VOICES = [
  { value: 'Mark', text: 'Mark' },
  { value: 'Nichalia', text: 'Nichalia' },
  { value: 'Hope', text: 'Hope' },
  { value: 'Chris', text: 'Chris' },
  { value: 'Archer', text: 'Archer' },
];
