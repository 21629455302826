/* RESPONSIBLE TEAM: team-phone */
/* eslint-disable @intercom/intercom/no-bare-strings */

export type FinVoicePipelineOptions = {
  value: FinVoicePipeline;
  text: string;
};

export type FinVoicePipeline =
  | 'default'
  | 'baseline-mode-v3'
  | 'realtime-only-mode-v1'
  | 'realtime-out-of-band-v1'
  | 'realtime-latest-model-v1'
  | 'realtime-phased-answer-v1';

export const FIN_VOICE_PIPELINE_OPTIONS: FinVoicePipelineOptions[] = [
  { value: 'default', text: 'Default' },
  { value: 'baseline-mode-v3', text: 'Baseline Mode v3' },
  { value: 'realtime-only-mode-v1', text: 'Realtime Only Mode v1' },
  { value: 'realtime-out-of-band-v1', text: 'Realtime Out of Band' },
  { value: 'realtime-latest-model-v1', text: 'Realtime Latest Model v1' },
  { value: 'realtime-phased-answer-v1', text: 'Realtime Phased Answer v1' },
];
