/* RESPONSIBLE TEAM: team-knowledge-foundations */

import $ from 'jquery';
import type Article from 'embercom/models/article';

interface ArticleCard {
  articleId: string;
  linkType: string;
  class: string;
  type: string;
  title: string;
  author: {
    name: string;
    avatar: string;
    firstName: string;
    lastName: string;
  };
  description: string;
  text: string;
  url: string;
  createdAt: Date;
  updatedAt: Date;
}

export default function buildArticleCard(article: Article): ArticleCard {
  return {
    articleId: article.id,
    linkType: 'educate.article',
    class: 'card',
    type: 'link',
    // eslint-disable-next-line ember/no-jquery
    text: $('<a>', { href: article.publicUrl, text: article.title })[0].outerHTML,
    title: article.title,
    author: {
      // @ts-ignore
      name: article.get('author.name'),
      // @ts-ignore
      avatar: article.get('author.avatar.image_urls.square_50'),
      // @ts-ignore
      firstName: article.get('author.first_name'),
      // @ts-ignore
      lastName: article.get('author.last_name'),
    },
    url: article.publicUrl,
    description: article.summary,
    createdAt: article.createdAt,
    updatedAt: article.updatedAt,
  };
}
